import moment from 'moment-timezone';
import accounting from 'accounting';

export const isJsonString = str => {
  if (str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

export function rangeDesc(start, end) {
  return Array(start - end + 1)
    .fill()
    .map((_, idx) => start - idx);
}

export const today = moment().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD');

export const formatDate = (date, format = 'MM/DD/YYYY') => moment(date).format(format);

export const getInitFilerChart = () => ({
  startDate: new Date(formatDate(moment(new Date()).subtract(6, 'days').add(1, 'day'))),
  endDate: new Date(formatDate(moment(new Date()).add(1, 'day'))),
});

export const downloadFile = (file, name) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getDomainUrl = url => {
  const matchResult = url?.match(/^https?:\/\/([a-zA-Z0-9.-]+)\.com$/);
  if (matchResult && matchResult.length === 2) {
    return matchResult[1];
  }
};

export const getUrlWebsite = domain => {
  return 'https://' + domain + '.com';
};

export const isObjectNameInArray = (targetName, array, key) => array?.some(element => element[key] === targetName);

export const convertToTitleCase = str => {
  const words = str.split('_');
  const capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  const convertedStr = capitalizedWords.join(' ');
  return convertedStr;
};

export function formatNumber(value, decimal = 2) {
  return accounting.formatNumber(value, decimal, '').replace(/.00$/g, '');
}

export const SystemLogsText = {
  DELETE_CATEGORY_SUCCESS: 'Xoá danh mục thành công',
  UPDATE_COIN_SUCCESS: 'Bù/ trừ coin thành công',
  DELETE_MISSON_SUCCESS: 'Xoá nhiệm vụ thành công',
  CREATE_SHIP_ORDER_SUCCESS: 'Tạo đơn hàng đang giao thành công',
  CHANGE_STATUS_SUCCESS: 'Đổi trạng thái thành công',
  DELETE_ORDER_SUCCESS: 'Xoá đơn hàng thành công',
  CREATE_POST_SUCCESSFULLY: 'Tạo nội dung hệ thống thành công',
  UPDATE_POST_SUCCESSFULLY: 'Cập nhật nội dung hệ thống thành công',
  DELETE_POST_SUCCESSFULLY: 'Xoá nội dung hệ thống thành công',
  CREATE_PRODUCT_SUCCESS: 'Tạo sản phẩm thành công',
  UPDATE_PRODUCT_SUCCESS: 'Cập nhật sản phẩm thành công',
  DELETE_PRODUCT_SUCCESS: 'Xoá sản phẩm thành công',
  CHANGE_STATUS_DONE: 'Đổi trạng thái hoàn thành',
  CREATE_OR_UPDATE_BOOK_MARKER: 'Tạo/ cập nhật book marker thành công',
  DELETE_BOOK_MARKER: 'Xoá book marker thành công',
  CANCEL_SHIP_ORDER_SUCCESS: 'Huỷ đơn hàng đang giao thành cồng',
  UPDATE_STORE_ADDRESS_SUCCESS: 'Cập nhật địa chỉ kho hàng thành công',
  CREATE_STORE_ADDRESS_SUCCESS: 'Tạo địa chỉ kho hàng thành công',
  CREATE_CATEGORY_SUCCESS: 'Tạo danh mục thành công',
  UPDATE_CATEGORY_SUCCESS: 'Cập nhật danh mục thành công',
  UPDATE_MISSION_SUCCESS: 'Cập nhật nhiệm vụ thành công',
  CREATE_MISSON_SUCCESS: 'Tạo nhiệm vụ thành công',
};

export const getLogDetail = logData => {
  if (logData?.note) {
    // switch (logData?.note?.type) {
    //   case 'ADD_COIN':
    //     return `Cộng ${logData?.note?.amount} coin cho ${logData?.note?.username} thành công`;
    //   case 'SUBTRACT_COIN':
    //     return `Trừ ${logData?.note?.amount} coin cho ${logData?.note?.username} thành công`;

    //   default:
    //     return SystemLogsText[logData?.action];
    // }
    return logData?.note?.msg;
  }

  return SystemLogsText[logData?.action];
};
