import { Box, Flex, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { FORMAT_DATE, ModalType, PERMISSIONS } from 'constants/common';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const GameTable = ({ tableData, refetch, handleUpdateItem }) => {
  const history = useHistory();
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const { userInfo } = useUserState();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Định danh',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('ip', {
        header: 'IP',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex gap={1}>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={PERMISSIONS.UPDATE_WHITELIST}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleUpdateItem(info?.row?.original, ModalType.Add);
                }}
              >
                <SettingsIcon boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>

            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={PERMISSIONS.DELETE_WHITELIST}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleUpdateItem(info?.row?.original, ModalType.Delete);
                }}
              >
                <DeleteIcon color="red.400" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
          </Flex>
        ),
      }),
    ],
    [tableData]
  );

  const table = useReactTable({
    data: tableData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} minW="120px">
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={3}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default GameTable;
