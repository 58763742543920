import React from 'react';
import { HomeIcon, PersonIcon } from 'components/Icons/Icons';
import { IoBook, IoListCircleOutline } from 'react-icons/io5';
import { MdChecklist, MdPeopleOutline, MdOutlineSettingsEthernet, MdDesktopAccessDisabled } from 'react-icons/md';
import { GiReceiveMoney } from 'react-icons/gi';
import { GiSolarSystem } from 'react-icons/gi';
import { FaMapMarkerAlt, FaShippingFast, FaRegImage } from 'react-icons/fa';
import { BiFoodMenu } from 'react-icons/bi';
import Dashboard from 'views/Dashboard/Dashboard.js';
import SignUp from 'views/Pages/SignUp.js';
import SignIn from 'views/Pages/SignIn';
import Profile from 'views/Dashboard/Profile';
import { PERMISSIONS, Roles } from 'constants/common';
import Category from 'views/Dashboard/Category/Category';
import Colors from 'views/Dashboard/Colors';
import Size from 'views/Dashboard/Size';
import Product from 'views/Dashboard/Product';
import ProductForm from 'views/Dashboard/Product/components/ProductForm';
import Order from 'views/Dashboard/Order';
import Shipping from 'views/Dashboard/Shipping';
import Members from 'views/Dashboard/Members/Members';
import Address from 'views/Dashboard/Address/Address';
import Notification from 'views/Dashboard/Notification';
import NotificationForm from 'views/Dashboard/Notification/components/NotificationForm';
import SystemNotification from 'views/Dashboard/SystemNotification';
import SystemNotificationForm from 'views/Dashboard/SystemNotification/components/NotificationForm';
import Mission from 'views/Dashboard/Mission';
import MissionForm from 'views/Dashboard/Mission/components/MissionForm';
import StatusSystem from 'views/Dashboard/StatusSystem';
import ShipOrder from 'views/Dashboard/ShipOrder';
import ShipOrderForm from 'views/Dashboard/ShipOrder/components/ShipOrderForm';
import ShipAddressForm from 'views/Dashboard/ShipOrder/components/ShipAddressForm';
import Histories from 'views/Dashboard/Members/components/Histories';
import MemberMissions from 'views/Dashboard/Members/components/MemberMissions';
import Post from 'views/Dashboard/Post';
import PostForm from 'views/Dashboard/Post/components/PostForm';
import BookMarker from 'views/Dashboard/BookMarker';
import Admin from 'views/Dashboard/Admin';
import HistoryMember from 'views/Dashboard/HistoryMember/HistoryMember';
import HistoriesUpdateCoin from 'views/Dashboard/HistoryMember/components/HistoriesUpdateCoin';
import OrderCompleted from 'views/Dashboard/OrderCompleted';
import Whitelist from 'views/Dashboard/Whitelist';
import SystemLogs from 'views/Dashboard/SystemLogs';
import AccessPermission from 'views/Dashboard/AccessPermission';

var dashRoutes = [
  // {
  //   path: '/dashboard',
  //   name: 'Tổng quan',
  //   icon: <HomeIcon color="inherit" />,
  //   layout: '/admin',
  //   component: Dashboard,
  //   role: [Roles.ADMIN],
  // },
  {
    path: '/members/:id/histories',
    name: 'Lịch sử hội viên',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: Histories,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'VIEW_HISTORY_COLLECT_COIN_MEMBER',
  },
  {
    path: '/members/:id/histories-coin',
    name: 'Lịch sử bù/trừ xu',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: HistoriesUpdateCoin,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'VIEW_HISTORY_COLLECT_COIN_MEMBER',
  },
  {
    path: '/members/:id/missions',
    name: 'Nhiệm vụ hội viên',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: MemberMissions,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'VIEW_DETAIL_MISSION',
  },
  {
    path: '/list',
    name: 'Tài khoản quản trị',
    icon: <MdPeopleOutline color="inherit" />,
    layout: '/admin',
    component: Admin,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'VIEW_LIST_ADMIN',
  },
  {
    path: '/members',
    name: 'Hội viên',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: Members,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: ['VIEW_LIST_MEMBER', 'VIEW_DETAIL_MEMBER'],
  },
  {
    path: '/category/:id/size',
    name: 'Size',
    icon: <PersonIcon color="inherit" />,
    redirect: true,
    layout: '/admin',
    component: Size,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: '',
  },
  {
    path: '/category/:id/colors',
    name: 'Colors',
    icon: <PersonIcon color="inherit" />,
    redirect: true,
    layout: '/admin',
    component: Colors,
    groupName: '',
  },
  {
    path: '/category',
    name: 'Thể loại',
    icon: <IoBook color="inherit" />,
    layout: '/admin',
    component: Category,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'VIEW_LIST_PRODUCT_CATEGORY',
  },
  {
    path: '/product/create',
    name: 'Tạo sản phẩm',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: ProductForm,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'UPDATE_PRODUCT',
  },
  {
    path: '/product/:id',
    name: 'Chi tiết sản phẩm',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: ProductForm,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'VIEW_DETAIL_PRODUCT',
  },
  {
    path: '/product',
    name: 'Sản phẩm',
    icon: <IoListCircleOutline size={20} />,
    layout: '/admin',
    component: Product,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'VIEW_LIST_PRODUCT',
  },
  {
    path: '/mission',
    name: 'Nhiệm vụ',
    icon: <MdChecklist size={20} />,
    layout: '/admin',
    component: Mission,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'UPDATE_MISSION',
  },
  {
    path: '/history-collect-coin',
    name: 'Lịch sử giao dịch xu',
    icon: <IoListCircleOutline size={20} />,
    layout: '/admin',
    component: HistoryMember,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'VIEW_HISTORY_COLLECT_COIN_MEMBER',
  },
  {
    path: '/mission-create/:id',
    name: 'Tạo nhiệm vụ',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: MissionForm,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'UPDATE_MISSION',
  },
  {
    path: '/mission-create',
    name: 'Tạo nhiệm vụ',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: MissionForm,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'UPDATE_MISSION',
  },
  {
    path: '/address',
    name: 'Địa chỉ kho hàng',
    icon: <IoBook color="inherit" />,
    layout: '/admin',
    component: Address,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'VIEW_ADDRESS_MEMBER',
  },
  {
    path: '/order',
    name: 'Đơn hàng',
    icon: <IoListCircleOutline size={20} />,
    layout: '/admin',
    component: Order,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'VIEW_LIST_NO_ADDRESS_ORDER',
  },
  {
    path: '/order-completed',
    name: 'Báo cáo đơn hàng',
    icon: <IoListCircleOutline size={20} />,
    layout: '/admin',
    component: OrderCompleted,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: '',
  },
  {
    path: '/ship-order',
    name: 'Vận chuyển đặt hàng',
    icon: <FaShippingFast size={20} />,
    layout: '/admin',
    component: ShipOrder,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'VIEW_LIST_SHIP_ORDER',
  },
  {
    path: '/ship-order-detail/:id',
    name: 'Tạo nhiệm vụ',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: ShipOrderForm,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'CREATE_SHIP_ORDER',
  },
  {
    path: '/ship-update-address/:id',
    name: 'Chi tiết sản phẩm',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: ShipAddressForm,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'UPDATE_ADDRESS_SHIP_ORDER',
  },
  // {
  //   path: '/notification',
  //   name: 'Thông báo',
  //   icon: <GiReceiveMoney color="inherit" />,
  //   layout: '/admin',
  //   component: Notification,
  //   role: [Roles.ADMIN, Roles.MANAGER],
  // },
  // {
  //   path: '/noti/create',
  //   name: 'Tạo thông báo',
  //   icon: <IoListCircleOutline size={20} />,
  //   redirect: true,
  //   layout: '/admin',
  //   component: NotificationForm,
  //   role: [Roles.ADMIN, Roles.MANAGER],
  //   groupName: 'UPDATE_NOTIFICATION',
  // },
  {
    path: '/bookmarker',
    name: 'Quản lý hệ thống',
    icon: <FaMapMarkerAlt size={20} />,
    layout: '/admin',
    component: BookMarker,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'UPDATE_LINK_SYSTEM',
  },
  // {
  //   path: '/system-notification',
  //   name: 'Thông báo hệ thống',
  //   icon: <GiReceiveMoney color="inherit" />,
  //   layout: '/admin',
  //   component: SystemNotification,
  //   role: [Roles.ADMIN, Roles.MANAGER],
  //   groupName: 'UPDATE_NOTIFICATION',
  // },
  // {
  //   path: '/system-noti/create',
  //   name: 'Tạo thông báo hệ thống',
  //   icon: <IoListCircleOutline size={20} />,
  //   redirect: true,
  //   layout: '/admin',
  //   component: SystemNotificationForm,
  //   role: [Roles.ADMIN, Roles.MANAGER],
  //   groupName: 'UPDATE_NOTIFICATION',
  // },
  // {
  //   path: '/shipping',
  //   name: 'Vận chuyển',
  //   icon: <IoListCircleOutline size={20} />,
  //   layout: '/admin',
  //   component: Shipping,
  //   role: [Roles.ADMIN, Roles.MANAGER],
  // },
  {
    path: '/status-system',
    name: 'Trạng thái hệ thống',
    icon: <GiSolarSystem size={20} />,
    layout: '/admin',
    component: StatusSystem,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'UPDATE_MAINTAIN_SYSTEM',
  },
  {
    path: '/setting-system/create',
    name: 'Nội dung hệ thống',
    icon: <FaRegImage size={18} />,
    layout: '/admin',
    component: PostForm,
    role: [Roles.ADMIN, Roles.MANAGER],
    redirect: true,
    groupName: 'VIEW_LIST_CONTENT',
  },
  {
    path: '/setting-system/:id',
    name: 'Tạo nhiệm vụ',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: PostForm,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'UPDATE_MISSION', //UPDATE_BANNER, UPDATE_TERMS, UPDATE_POLICY, UPDATE_RULES
  },
  {
    path: '/setting-system',
    name: 'Nội dung hệ thống',
    icon: <FaRegImage size={18} />,
    layout: '/admin',
    component: Post,
    role: [Roles.ADMIN, Roles.MANAGER],
    groupName: 'VIEW_LIST_CONTENT',
  },
  // {
  //   path: '/vendor',
  //   name: 'Người bán',
  //   icon: <IoListCircleOutline size={20} />,
  //   layout: '/admin',
  //   component: Vendor,
  //   role: [Roles.ADMIN, Roles.MANAGER],
  // },
  // {
  //   path: '/order',
  //   name: 'Đơn hàng',
  //   icon: <IoListCircleOutline size={20} />,
  //   layout: '/admin',
  //   component: Order,
  //   role: [Roles.ADMIN, Roles.MANAGER],
  // },
  // {
  //   path: '/shipping',
  //   name: 'Vận chuyển',
  //   icon: <IoListCircleOutline size={20} />,
  //   layout: '/admin',
  //   component: Shipping,
  //   role: [Roles.ADMIN, Roles.MANAGER],
  // },
  {
    path: '/white-list',
    name: 'IP whitelist',
    icon: <MdOutlineSettingsEthernet size={20} />,
    layout: '/admin',
    component: Whitelist,
    role: [Roles.ADMIN],
    groupName: [PERMISSIONS.VIEW_WHITELIST],
  },
  {
    path: '/system/logs',
    name: 'Tracking hệ thống',
    icon: <MdOutlineSettingsEthernet size={20} />,
    layout: '/admin',
    component: SystemLogs,
    role: [Roles.ADMIN],
    groupName: [PERMISSIONS.VIEW_WHITELIST],
  },
  // {
  //   path: '/access-permission',
  //   name: 'Quyền truy cập',
  //   icon: <MdDesktopAccessDisabled size={20} />,
  //   layout: '/admin',
  //   component: AccessPermission,
  //   role: [Roles.ADMIN],
  //   groupName: [PERMISSIONS.VIEW_WHITELIST],
  // },
  {
    name: 'ACCOUNT PAGES',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
      {
        path: '/profile',
        name: 'Profile',
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        layout: '/admin',
        component: Profile,
        role: [Roles.ADMIN, Roles.MANAGER, Roles.USER, Roles.GUEST],
        groupName: 'RESET_PASSWORD_ADMIN',
      },
      {
        path: '/sign-in',
        layout: '/auth',
        redirect: true,
        component: SignIn,
      },
      {
        path: '/sign-up',
        layout: '/auth',
        redirect: true,
        component: SignUp,
      },
    ],
  },
];

export default dashRoutes;
