import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getListIP = params => request.get('/api/v1/ips', { params });
export const createIP = data => request.post('/api/v1/ips', data);
export const updateIP = data => request.post('api/v1/ips/update', data);
export const deleteIP = data => request.post('/api/v1/ips/delete', data);
export const getSystemLogs = params => request.get('/api/v1/action-user', { params });

export const getListDomainAccess = params => request.get('/api/v1/setting-domains', { params });
export const addDomainAccess = data => request.post('/api/v1/setting-domain', data);
export const updateDomainAccess = data => request.post('api/v1/setting-domain/update', data);
export const deleteDomainAccess = data => request.post('/api/v1/setting-domain/delete', data);
export const changeDomainAccessStatus = data => request.post('/api/v1/setting-domain/change-status', data);

// Mutation
export const useCreateIPMutation = () => useMutation({ mutationFn: createIP });
export const useUpdateIPMutation = () => useMutation({ mutationFn: updateIP });
export const useDeleteIPMutation = () => useMutation({ mutationFn: deleteIP });
export const useAddDomainAccess = () => useMutation({ mutationFn: addDomainAccess });
export const useUpdateDomainAccess = () => useMutation({ mutationFn: updateDomainAccess });
export const useDeleteDomainAccess = () => useMutation({ mutationFn: deleteDomainAccess });
export const useChangeDomainAccessStatus = () => useMutation({ mutationFn: changeDomainAccessStatus });

// Query
export const useQueryGetListIP = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_IP_LIST', params], queryFn: () => getListIP(params), ...options });
export const useQueryGetSystemLogs = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_SYSTEM_LOGS', params], queryFn: () => getSystemLogs(params), ...options });
export const useQueryGetListDomainAccess = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_LIST_DOMAIN_ACCESS', params], queryFn: () => getListDomainAccess(params), ...options });
