import { Button, Card, CardBody, CardHeader, Flex, FormControl, FormLabel, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Select } from 'chakra-react-select';
import moment from 'moment';
import { CookieStorage } from 'utils/cookie-storage';
import Pagination from 'components/Pagination/Pagination';
import { useQueryGetSystemLogs } from 'services/system';
import Table from './components/Table';
import { ActionTypeOption } from 'constants/common';
import DatePicker from 'components/DatePicker/DatePicker';

function SystemLogs() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [actionType, setActionType] = useState(undefined);
  const [filterDate, setFilterDate] = useState({
    start: null,
    end: null,
  });

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  const { data: systemLogsData, refetch } = useQueryGetSystemLogs({ ...filter }, { enabled: CookieStorage.isAuthenticated() });

  const handleSearch = () => {
    setFilter({
      ...filter,
      type: actionType?.value,
      ...(filterDate?.start && { start: moment(filterDate?.start).startOf('day').toISOString() }),
      ...(filterDate?.end && { end: moment(filterDate?.end).endOf('day').toISOString() }),
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setActionType(undefined);
    setFilterDate({
      start: null,
      end: null,
    });
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'}>
                <Flex direction="column" gap={'30px'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    QUẢN LÝ HỆ THỐNG {'>'} LOG THAO TÁC
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap={'20px'} mt="12px">
              <Flex alignItems={'center'} gap={'16px'} flexWrap="wrap">
                <FormControl w={'300px'}>
                  <FormLabel h={'21px'}>Hệ thống</FormLabel>
                  <Select
                    placeholder="Chọn hệ thống"
                    isClearable
                    menuShouldBlockScroll
                    value={actionType}
                    onChange={e => {
                      setActionType(e);
                    }}
                    options={ActionTypeOption}
                  />
                </FormControl>
                <FormControl display="flex" flexDirection="column" maxW="300px">
                  <FormLabel marginRight={0} fontSize={14} isTruncated>
                    Ngày bắt đầu
                  </FormLabel>
                  <DatePicker
                    styleInput={{
                      minWidth: '300px',
                    }}
                    selectedDate={filterDate.start}
                    onChange={date => onChangeDate('start')(date)}
                  />
                </FormControl>
                <FormControl display="flex" flexDirection="column" maxW="300px">
                  <FormLabel marginRight={0} fontSize={14} isTruncated>
                    Ngày kết thúc
                  </FormLabel>
                  <DatePicker
                    styleInput={{
                      minWidth: '300px',
                    }}
                    selectedDate={filterDate.end}
                    minDate={filterDate.start}
                    // maxDate={new Date(formatDate(moment(filter.start).add(6, 'days')))}
                    onChange={date => onChangeDate('end')(date)}
                  />
                </FormControl>
              </Flex>
              <Flex justifyContent="flex-end" gap="12px">
                <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                  <Text fontSize="md" fontWeight="bold" cursor="pointer">
                    Cài lại
                  </Text>
                </Button>
                <Button bg="#3182ce" color="#fff" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                  <Text fontSize="md" fontWeight="bold" cursor="pointer">
                    Tìm kiếm
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <Table tableData={systemLogsData?.data || []} refetch={refetch} />
            </Stack>
            {!isEmpty(systemLogsData?.data) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={systemLogsData?.pagination?.page}
                  pageLength={systemLogsData?.pagination?.pageSize}
                  totalRecords={systemLogsData?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Flex>
    </>
  );
}

export default SystemLogs;
